<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <div></div>
        <button v-if="examID" class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportExamResult">
            <span v-if="!exportLoading" class="indicator-label">
                <span class="svg-icon svg-icon-1">
                    <inline-svg src="/media/icons/duotune/files/fil021.svg" />
                </span>
                {{ $t("common.export") }}
            </span>

            <span v-if="exportLoading" class="indicator-progress">
                <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                {{ $t("messages.wait") }}
            </span>
        </button>
    </div>
    <custom-table
        :title="$t('pages.module.examManagement.exam.result.title')"
        :subTitle="$t('pages.module.examManagement.exam.result.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleExamResultFilter"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:user="{ row: record }">
            <router-link :to="'/ecommerce/user?userID=' + record.user_session.user.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ record.user_session.user.full_name }}</span>
            </router-link>
            <br>
            <a :href="'mailto:' + record.user_session.user.email" class="text-gray-600 text-hover-primary mb-1 cursor-pointer">
                {{ record.user_session.user.email }}
            </a>
        </template>
        <template v-slot:examInfo="{ row: record }">
            <router-link :to="'/module/exam-management/exam/save/' + record.user_session.exam.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ resolveDatum(record.user_session.exam.translations, this.$root.defaultLanguage.id, 'language_id').title }}</span>
            </router-link>
            <br>
            <span class="text-gray-600 fs-7">{{ $moment(record.user_session.session.session_date).format("DD.MM.YYYY") + ' ' + record.user_session.session.session_time}}</span>
        </template>
        <template v-slot:grade="{ row: record }">
            {{ parseFloat(record.grade) }}
        </template>
        <template v-slot:spendedTime="{ row: record }">
            {{ getSpendedTime(record.spended_time)  }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="'/module/exam-management/exam/result/' + record.id+'/detail'" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                     </span>
                </router-link>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.user"),
                    scopedSlots: {customRender: "user"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.examInfo"),
                    scopedSlots: {customRender: "examInfo"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.total"),
                    key: "total",
                    class: "",
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.answered"),
                    key: "answered",
                    class: "",
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.unanswered"),
                    key: "unanswered",
                    class: "",
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.correct"),
                    key: "correct",
                    class: "",
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.wrong"),
                    key: "wrong",
                    class: "",
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.net"),
                    key: "net",
                    class: "",
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.grade"),
                    scopedSlots: {customRender: "grade"},
                    class: "",
                },
                {
                    name: this.$t("pages.module.examManagement.exam.result.cols.spendedTime"),
                    scopedSlots: {customRender: "spendedTime"},
                    class: "",
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            filterParameters: {},
            exportLoading: false
        }
    },
    computed: {
        table() {
            return this.$store.state.module.exam.result.table;
        },
        examID(){
            return this.$route.query.examID;
        },
        userID(){
            return this.$route.query.userID;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.examManagement.exam.result.title"), [this.$t("menu.moduleManagement"), this.$t("pages.module.examManagement.title")]);

        this.filterParameters = {
            exam_id: this.examID,
            user_id: this.userID
        };

        this.$store.dispatch('module/exam/result/get', {
            page: {},
            filterData: Object.assign(this.$root.getFilterWithUrl({}), this.filterParameters)
        });
    },
    methods: {
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/exam/result/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                exam_id: this.examID,
                user_id: this.userID
            }, filterData);

            this.$store.dispatch("module/exam/result/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        getSpendedTime(spendedTime){
            let time = this.$moment.utc(spendedTime*1000);
            let timeParts = {hour: {format: 'HH'}, minute: {format: 'mm'}, second: {format: 'ss'}};
            let timeText = [];

            for(let key in timeParts){
                let value = parseInt(time.format(timeParts[key].format));
                timeParts[key].value = value;
                timeParts[key].text = this.sprintf('%d %s', [value, this.$t('common.' + key).toLowerCase()]);
            }

            if(timeParts.hour.value > 0){
                timeText = [timeParts.hour.text, timeParts.minute.text, timeParts.second.text];
            } else if(timeParts.minute.value > 0) {
                timeText = [timeParts.minute.text, timeParts.second.text];
            } else {
                timeText = [timeParts.second.text]
            }

            return timeText.join(', ');
        },
        exportExamResult(){
            let filterParameters = this.cloneData(this.filterParameters);
            delete filterParameters.exam_id;

            this.exportLoading = true;
            this.axios({
                url: this.endpoints['module_exam_result'] + '/' + this.examID + '/export/excel',
                method: 'get',
                responseType: "blob",
                params: filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>